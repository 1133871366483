<template>
  <base-web-page>
    <template v-slot:main>
        <el-main style="padding:60px 0px;margin:0 auto;width:100%;max-width:1300px;">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:0px 10px;">
          <el-breadcrumb-item to="/">Home</el-breadcrumb-item>
          <el-breadcrumb-item>{{currentCategory.name}}</el-breadcrumb-item>
        </el-breadcrumb>
        <h1>{{currentCategory.name}}</h1>
        <el-row v-loading="loading">
            <el-col class="product-item-col" :span="6" v-for="item in products" :key="item.ID">
                <product-item :item="item"></product-item>
            </el-col>
        </el-row>
        <div style="margin-top:40px;">
            <el-pagination background layout="prev, pager, next" :total="productCount" :page-size="pageSize" :current-page="pageIndex" @current-change="handlePageChange"></el-pagination>
        </div>
      </el-main>
    </template>
  </base-web-page>
</template>

<script>
import ProductItem from '../../components/widgets/ProductItem.vue'
import BaseWebPage from '../../components/base/BaseWebPage.vue'
import ModelProductList from '../viewmodel/ModelProductList.vue'

export default {
    extends: ModelProductList,
    name: 'productList',
    components:{
        BaseWebPage,
        ProductItem,
    }
}
</script>

<style>
</style>